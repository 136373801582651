import { useTranslation } from 'react-i18next'
// @mui
import { enUS, thTH, zhCN } from '@mui/material/locale'

// ----------------------------------------------------------------------

const LANGS = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/flag/ic_flag_en.svg',
  },
  {
    label: 'Thai',
    value: 'th',
    systemValue: thTH,
    icon: '/assets/flag/th.png',
  },
  {
    label: '中文',
    value: 'cn',
    systemValue: zhCN,
    icon: '/assets/flag/cn.png',
  },
]

export default function useLocales() {
  const { i18n, t: translate } = useTranslation()
  const langStorage = localStorage.getItem('i18nextLng')
  const currentLang =
    LANGS.find((_lang) => _lang.value === langStorage) || LANGS[1]

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang)
  }

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  }
}
